var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mainBox",
    { attrs: { mClass: "lgMbox" } },
    [
      _c("div", { staticClass: "wapBox" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "eBtns" },
            [
              _c(
                "el-button",
                {
                  staticClass: "b_it",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.toAddOrEdit()
                    },
                  },
                },
                [_vm._v("新增")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "b_it",
                  attrs: { icon: "el-icon-refresh-right" },
                  on: { click: _vm.initTable },
                },
                [_vm._v("刷新")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tbBox" },
            [
              _c("FinalTable", {
                ref: "finalTableRef",
                attrs: { datas: _vm.dataset },
                on: { tableEventHandler: _vm.tableEventHandler },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.addInfo.open
        ? _c("AddPopup", {
            attrs: {
              isOpen: _vm.addInfo.open,
              dataId: _vm.addInfo.dataId,
              datas: _vm.addInfo.rowData,
            },
            on: { close: _vm.closeAddEditPup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }