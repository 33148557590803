<template>
  <mainBox mClass="lgMbox">
    <div class="wapBox">
      <div>
        <div class="eBtns">
          <el-button class="b_it" @click="toAddOrEdit()" type="primary"
            >新增</el-button
          >
          <el-button
            class="b_it"
            @click="initTable"
            icon="el-icon-refresh-right"
            >刷新</el-button
          >
        </div>
        <div class="tbBox">
          <FinalTable
            ref="finalTableRef"
            :datas="dataset"
            @tableEventHandler="tableEventHandler"
          />
        </div>
      </div>
    </div>
    <AddPopup
      v-if="addInfo.open"
      :isOpen="addInfo.open"
      @close="closeAddEditPup"
      :dataId="addInfo.dataId"
      :datas="addInfo.rowData"
    />
  </mainBox>
</template>
<script>
import AddPopup from "./model/AddPopup.vue";
import FinalTable from "@/components/FinalTable";

export default {
  components: { FinalTable, AddPopup },
  data() {
    return {
      loadingFlag: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          { prop: "functionName", label: "功能名称", width: "" },
          { prop: "creationUserName", label: "创建人", width: "" },
          { prop: "creationDate", label: "创建时间", width: "" },
          { prop: "updateUserName", label: "修改人", width: "" },
          { prop: "lastUpdateDate", label: "修改时间", width: "" },
          { prop: "operation", label: "操作", width: "120" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          functionName: {
            type: "input",
            label: "功能名称",
            actionType: "goSearch",
            value: "",
            placeholder: "功能名称",
          },
        },
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          lastUpdateDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      addInfo: {
        //新增，编辑的弹框信息
        open: false,
        dataId: "",
        rowData: {},
      },
      filterObj: {
        current: 1,
        rowCount: 10,
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.initTable();
  },
  methods: {
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.filterObj.current = 1;
        this.filterObj = { ...this.filterObj, ...datas.params };
        this.initTable();
      } else if (datas.type === "paginationChange") {
        this.filterObj.current = datas.params.current.page;
        this.filterObj.rowCount = datas.params.current.limit;
        this.initTable();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "delete":
            this.tbRowDel(datas.row);
            break;
          case "edit":
            this.toAddOrEdit(datas.row);
            break;
        }
      }
    },
    async initTable() {
      this.loadingFlag = true;
      let res = await this.ApiHttp(
        "/legoBuilder/miniprogramFunction/page",
        this.filterObj
      );
      res.rows.map((it) => {
        it.iconsArr = [];
        if (it.functionIcon)
          it.iconsArr.push({ fileId: it.functionIcon, fileType: "image/jpeg" }); //拼装前端需要编辑的图片
      });
      this.dataset.tableData = res.rows || [];
      this.dataset.pageVO.total = res.total;
      this.$nextTick(() => {
        this.loadingFlag = false;
      });
    },
    toAddOrEdit(row) {
      //点击打开弹窗
      let dataId = "";
      if (row) dataId = row.functionId;
      this.addInfo = {
        open: true,
        dataId,
        rowData: { ...row },
      };
    },
    tbRowDel(row) {
      this.$confirm("是否确认删除？", "提示")
        .then(() => {
          this.ApiHttp(
            "/legoBuilder/miniprogramFunction/delete/" + row.functionId,
            {},
            "delete"
          ).then((res) => {
            if (res) {
              this.initTable();
              this.$message({ type: "success", message: "删除成功" });
            }
          });
        })
        .catch(() => {});
    },
    closeAddEditPup(isInit) {
      //关闭添加编辑弹框
      this.addInfo = {
        open: false,
        dataId: "",
        rowData: {},
      };
      if (isInit) {
        this.filterObj.current = 1;
        this.initTable();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wapBox {
  height: 100%;
  .flexs {
    height: 100%;
  }

  .right {
    padding: 16px 24px;
    .eBtns {
      position: relative;
      .el-button {
        padding: 10px 18px;
      }
      .sxBtn {
        position: absolute;
        right: 0;
      }
    }
  }
  .txColor0 {
    padding: 0 6px;
    color: #4e78ff;
    background: #f4f6fe;
  }
  .txColor4 {
    padding: 0 6px;
    color: #78d549;
    background: #f2faee;
  }
  .txColor5 {
    padding: 0 6px;
    color: #e8331c;
    background: #fef5f4;
  }
}
</style>
