<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑功能':'添加功能'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="600px" :before-close="closeMypup">
			<div >
				<TForm formClass='legoTform' ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="tagsArrs" slot-scope="scope">
						
					</template>
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="cancelBtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 添加</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			datas:{
				type: Object,
				default:()=>{
					return{
						
					}
				}
				
			}
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{},
	  			isHttping:false,
	  			formlist:[
	  				{name: '功能名称',keys:'functionName',value:'',type:'input',isMust:true},
	  				{name: '链接',keys: 'functionUrl',value: '',type: 'input',isMust:true},
	  				{name: '功能类型',keys: 'functionType',value: '',type: 'select',options:[],isMust:true},
	  				{name: '上传图标',keys: 'iconsArr',value:[],type: 'imgVideo',allowAdd:true,isMust:true,formItem:true,maxlimt:1,
	  				imgWidth:'100px',imgHeight:'100px',diyClass:'lgoImgs',tips:'温馨提示：</br> 最多上传1张，图片大小不能超过2m，图片格式为jpg，png。',tipleft:'118px'},
	  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					
				}
			},
			
		},
		created() {
			this.resetTFrom();
		},
		mounted() {
			this.initTypeArrs();
			this.initClassArrs();
			this.getDatasById();
			this.$nextTick(() => {
				this.$refs.htmlFrom.resetFields();
			})
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			async initTypeArrs(){
				let res = await this.ApiHttp("/lookup/lookup/item/find", {
			        isloading: false,
			        classifyCode: "FUNCTION_TYPE",
		      	});
				if(res){
					res.lookupItemList.map(it=>{
						it.name = it.itemName;
          				it.code = it.itemCode;
          				
          				it.label = it.itemName;
					})
					this.common.insertOptions(this.formlist,'functionType',res.lookupItemList);//分类数据
				}
				
			},
			async initClassArrs(){
				//获取左侧树形列表数据
				let res = await this.ApiHttp('/base/tenant/materielcategory/list');
				if(res){
  				res.map(it=>{
						it.name = it.materielCategoryName;
						it.code = it.materielCategoryId;
					})
					this.common.insertOptions(this.formlist,'materielCategoryId',res);//物料分类数据
				}
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
		    changeCompanyName(val) {
		    	// 联动企业名称  生成编码
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.materielCode=n;
		    },
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			async getDatasById(){
				if(this.dataId){
	  				this.htmlDatas={...this.datas};
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					...this.htmlDatas,
				}
				if(params.iconsArr&&params.iconsArr.length>0) params.functionIcon=params.iconsArr[0].fileId;
				delete params.iconsArr
				console.log(JSON.parse(JSON.stringify(params)))
				let urlType='save';
				let res = await this.ApiHttp('/legoBuilder/miniprogramFunction/'+urlType,params,'post');
				console.log(2222222,res)
				if(res){
					this.$message({type:'success',message:'操作成功'})
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.Y-tips .el-dialog__header{
	font-weight: 600;
	font-size: 18px;
	color: #252D3D;
}
.btmBtn{
	text-align: right;
	.cancelBtn{
		color: #B5BECE;
	}
	.el-button{
		border-radius: 8px;
	}
}

</style>
<style lang="scss">

.lgoImgs {
	position: relative;
	.tipTx{
		position: absolute;
		top: 34px;
		text-align: left;
		display: inline-block;
		width: 244px;
		color: #5D687C;
	}
}
</style>